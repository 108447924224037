import React from 'react'
import Link from '../components/Navigation/Link/Link'
import Layout from '../components/layout'
import ErrorPage from '../components/Various/ErrorPage/ErrorPage'
import notFoundGif from '../images/404.gif'
import { Col, Row } from '@headtrip/components-react'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout location={{ 'pathname': '/404' }}>
    <SEO title={'404 Error'} description={'The requested page does not exist. Please check the spelling of the URL. The page may have been deleted or is now out-of-date.'}/>
    <main>
      <section>
        <ErrorPage headline={'Page not found'}>
          <div className='content text-center'>
            <Row>
              <Col xs={{ span: 8, offset: 2 }}>
                <img src={notFoundGif}
                  className='d-block max-width-100 h-auto mx-auto mb-3'
                  alt='' />
                <p>
                  The requested page does not exist. Please check the spelling of the URL. The page may have been
                  deleted
                  or is now out-of-date.
                </p>
                <div className='text-center'>
                  <Link classNames={'btn btn-primary mr-2'}
                    to={'/'}>Back to Start
                  </Link>
                  <Link classNames={'btn btn-black-light'}
                    to={'https://www.hetzner.com/support-form'}>
                    Contact
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </ErrorPage>
      </section>
    </main>
  </Layout>
)

export default NotFoundPage
