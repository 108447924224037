import React, { Component } from 'react'
import { Container } from '@headtrip/components-react'
import * as PropTypes from 'underscore'

class ErrorPage extends Component {
  render () {
    return (
      <Container>

        {this.props.children}

      </Container>
    )
  }
}

export default ErrorPage

ErrorPage.propTypes = {
  children: PropTypes.any,
  headline: PropTypes.string
}
